import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components';

import { Layout } from '../components1/layout';
import { Title, Text, Bold } from '../components1/Texts';
import FlexDiv from '../components1/FlexDiv';
import Section from '../components1/Section';
import { CabadiciButton } from '../components1/buttons';

import Subtitle from '../components1/Subtitle2';
import Separator from '../components1/Separator';
import MiniCabadiciSelector from '../containers/grocery/MiniCabadiciSelector';
import GrocerySelector from '../containers/grocery/GrocerySelector';

const CenterText = styled(Text)`
    text-align: center;
    padding: 20px;
`;

// https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#avoiding-stretched-images-using-the-fluid-type
const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
                margin: "0 auto", // Used to center the image
            },
        }
    }
    return <BackgroundImage {...normalizedProps} />
}

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            backgroundParenthesesNoires: file(relativePath: { eq: "backgrounds/parentheses-noires-carre.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200, quality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                        presentationWidth
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            {
                ({ openConnectForm }) => (
                    <>
                        <FlexDiv
                            style={{paddingTop: 40, paddingBottom: 0}}
                            css={`
                                @media only screen and (max-width:599px) {
                                    padding-top: 15 !important;
                                }
                            `}
                        >
                            <Separator
                                style={{flex: 1}}
                                strokeColor="jauneFort"
                                strokeWidth="2.5em"
                            />
                            <Title
                                style={{flex: 1, textAlign: 'center', padding: 0}}
                                css={`
                                    @media only screen and (max-width:599px) {
                                        padding: 0 30px !important;
                                    }
                                `}
                            >
                                {'L\'épicerie'}
                            </Title>
                            <Separator
                                style={{flex: 1}}
                                strokeColor="jauneFort"
                                strokeWidth="2.5em"
                            />
                        </FlexDiv>
                        <FlexDiv style={{paddingTop: 10, paddingBottom: 30}}>
                            <Text
                                style={{
                                    flex: 1,
                                    fontSize: 24,
                                    textAlign: 'center',
                                    padding: 0
                                }}
                            >
                                pour compléter votre repas
                            </Text>
                        </FlexDiv>
                        <Subtitle
                            alignement="center"
                            position="center"
                            color="jauneFaible"
                            width="50%"
                            top={-20}
                        >
                            <Text
                                special
                                style={{
                                    fontSize: 50,
                                    fontWeight: 300,
                                }}
                            >Mini Recettes</Text>
                        </Subtitle>
                        <FlexDiv style={{paddingTop: 10, paddingBottom: 40}}>
                            <Text
                                style={{
                                    flex: 1,
                                    fontSize: 18,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    padding: 0
                                }}
                            >
                                ingrédients et recettes
                            </Text>
                        </FlexDiv>
                        <Section style={{marginBottom: 50}}>
                            <MiniCabadiciSelector />
                        </Section>
                        <Subtitle
                            alignement="center"
                            position="center"
                            color="jauneFaible"
                            width="50%"
                            top={-20}
                        >
                            <Text
                                special
                                style={{
                                    fontSize: 50,
                                    fontWeight: 300,
                                }}
                            >Prêt à manger</Text>
                        </Subtitle>
                        <FlexDiv style={{paddingTop: 20, paddingBottom: 30}}>
                            <Text
                                style={{
                                    flex: 1,
                                    fontSize: 18,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    padding: 0
                                }}
                            >
                                produits et assortiments de produits
                            </Text>
                        </FlexDiv>
                        <Section style={{marginBottom: 50}}>
                            <GrocerySelector />
                        </Section>
                        <FlexDiv style={{ justifyContent: 'center' }}>
                            <CabadiciButton
                                color="rougeFort"
                                onClick={openConnectForm}
                                style={{width: 170}}
                            >
                                commander
                            </CabadiciButton>
                        </FlexDiv>
                        <Separator strokeColor="vertFaible"
                            style={{
                                marginBottom: 60
                            }}
                        />
                        <Subtitle
                            alignement="center"
                            position="center"
                            color="jauneFaible"
                            width="50%"
                            top={-20}
                        >
                            <Text
                                special
                                style={{
                                    fontSize: 50,
                                    fontWeight: 300,
                                }}
                            >Juste pour Vous</Text>
                        </Subtitle>
                        <FlexDiv style={{paddingTop: 20, paddingBottom: 30}}>
                            <Text
                                style={{
                                    flex: 1,
                                    fontSize: 18,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    padding: 0
                                }}
                            >
                                ingrédients et recettes personnalisés
                            </Text>
                        </FlexDiv>
                        <Section>
                            <NonStretchedImage
                                fluid={data.backgroundParenthesesNoires.childImageSharp.fluid}
                                style={{
                                    // default values:
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundSize: 'cover',
                                    // backgroundPosition: 'center',

                                    backgroundSize: '100% 100%',
                                    objectFit: 'contain',

                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                    minWidth: '20rem',
                                    minHeight: '10rem',
                                    margin: 10,
                                    padding: 20,
                                    marginBottom: 40,
                                }}
                                fadeIn={false}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        min-width: 8rem !important;
                                        min-height: 4rem !important;
                                    }
                                `}
                            >
                                <CenterText
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.25rem',
                                        fontWeight: 600,
                                        minWidth: 300,
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 1.125rem !important;
                                        }
                                    `}
                                >
                                    Vous nous demandez
                                    <br />
                                    ce qui vous ferait plaisir,
                                    <br />
                                    {'on s\'occupe'}
                                    <br />
                                    des courses et des recettes !
                                </CenterText>
                            </NonStretchedImage>
                            <CenterText>
                                Pour vous donner des idées, voici quelques exemples de CABADICI personnalisés, sur devis :
                            </CenterText>
                            <FlexDiv
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        flex-direction: column !important;
                                    }
                                `}
                            >
                                <FlexDiv
                                    style={{
                                        flexDirection: 'column'
                                    }}
                                >
                                    <CenterText>
                                        <Bold>{'Un goûter d\'anniversaire'}<br />{'pour 10 enfants ?'}</Bold>
                                        <br/>
                                        <br/>
                                        Tout pour préparer les gourmandises et les boissons, selon vos envies et votre budget.
                                    </CenterText>
                                </FlexDiv>
                                <FlexDiv
                                    style={{
                                        flexDirection: 'column'
                                    }}
                                >
                                    <CenterText>
                                        <Bold>{'Un repas en famille pour 8 personnes,'}<br />{'un jour de fête ?'}</Bold>
                                        <br/>
                                        <br/>
                                        Entrée, plat et dessert, vous aurez tout pour cuisiner sans y penser, selon vos goûts et votre budget.
                                    </CenterText>
                                </FlexDiv>
                                <FlexDiv
                                    style={{
                                        flexDirection: 'column'
                                    }}
                                >
                                    <CenterText>
                                        <Bold>{'Un apéritif dînatoire entre amis,'}<br />{'qui en jette un peu ou simple et rapide ?'}</Bold>
                                        <br/>
                                        <br/>
                                        De quoi préparer des bouchées salées et sucrées, selon votre temps et votre budget.
                                    </CenterText>
                                </FlexDiv>
                            </FlexDiv>
                            <FlexDiv style={{ justifyContent: 'center', paddingTop: 30 }}>
                                <CabadiciButton
                                    color="rougeFort"
                                    style={{textDecoration: 'none', textAlign: 'center'}}
                                    as="a"
                                    href="mailto:contact@cabadici.fr"
                                >
                                    nous contacter
                                </CabadiciButton>
                            </FlexDiv>
                        </Section>
                        <Separator strokeColor="vertFaible"/>
                        <div style={{
                            marginBottom: 60
                        }}/>
                    </>
                )
            }
        </Layout>
    );
};

export default IndexPage;
